import React from "react";
import Loader from "react-loader-spinner";
const Loading = () => {
  return (
    <div className="fixed h-full w-full flex justify-center items-center bg-black z-20 ">
      <Loader type="MutatingDots" color="#1d9bf0" secondaryColor="#1d9bf0" height={100} width={100} visible />
    </div>
  );
};

export default Loading;
