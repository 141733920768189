/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

const platforms = [
  {
    id: "ETH",
    name: "Ethereum",
    avatar: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
  },
  {
    id: "SOL",
    name: "Solana",
    avatar: "	https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
  },
  {
    id: "IMX",
    name: "Immutable X",
    avatar: "https://s2.coinmarketcap.com/static/img/coins/64x64/10603.png",
  },
  {
    id: "BSC",
    name: "Binance",
    avatar: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
  },
  {
    id: "FTM",
    name: "Fantom",
    avatar: "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
  },
  {
    id: "MATIC",
    name: "Polygon",
    avatar: "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface PlatformSelectProps {
  onSelect: (id: string) => void;
}

export default function PlatformSelect({ onSelect }: PlatformSelectProps) {
  const [selected, setSelected] = useState(platforms[0]);
  const onChange = (platform) => {
    setSelected(platform);
    onSelect && onSelect(platform.id);
  };
  return (
    <Listbox value={selected} onChange={onChange}>
      <Listbox.Label className="block text-sm font-medium text-gray-700">
        Platform<span className=" text-red-500">*</span>
      </Listbox.Label>
      <div className="mt-1 relative">
        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <span className="flex items-center">
            <img src={selected.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
            <span className="ml-3 block truncate">{selected.name}</span>
          </span>
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>

        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {platforms.map((platform) => (
              <Listbox.Option
                key={platform.id}
                className={({ active }) =>
                  classNames(active ? "text-white bg-indigo-600" : "text-gray-900", "cursor-default select-none relative py-2 pl-3 pr-9")
                }
                value={platform}
              >
                {({ selected, active }) => (
                  <>
                    <div className="flex items-center">
                      <img src={platform.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                      <span className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}>{platform.name}</span>
                    </div>

                    {selected ? (
                      <span className={classNames(active ? "text-white" : "text-indigo-600", "absolute inset-y-0 right-0 flex items-center pr-4")}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
