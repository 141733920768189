import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Switch } from "@headlessui/react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PlatformSelect from "@/components/PlatformSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Loading from "@/components/Loading";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const schema = yup
  .object({
    name: yup.string().required().max(20),
    description: yup.string().required().max(250).min(10),
    totalSupply: yup.number().required().min(1),
    price: yup.number().required(),
    website: yup.string().url().required(),
    twitter: yup.string().url().required(),
    discord: yup.string().url().required(),
    whitelistJoinLink: yup.string().url(),
  })
  .required();
const SubmitProject: React.FC<unknown> = ({}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [agreed, setAgreed] = useState(false);
  const [launchDate, handleDateChange] = useState(new Date());
  const [platformId, setPlatformId] = useState("ETH");
  const [whitelistDate, handleWhitelistDateChange] = useState(undefined);
  const [logoFile, setLogoFile] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [submitResult, setSubmitResult] = useState("");
  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const logo = await fileToBase64(logoFile);
      await axios.post("https://us-central1-nftnaires.cloudfunctions.net/submitListing", {
        ...data,
        launchDate: launchDate.getTime(),
        whitelistDate: whitelistDate?.getTime(),
        platformId,
        logo,
      });
      reset();
      setAgreed(false);
      setSubmitResult("😍😍 We Received Your Request And Our Team 🥷🥷 Will Process it ASAP 👩‍💻 🧑‍💻.");
    } catch (error) {
      setSubmitResult(`🤦🏻‍♂️ 🤷🏻‍♀️ 🤷🏻‍♂️ We are sorry but something bad happened while Processing your Request, Please Try again and if this persist please reach out to us on Discord.
      We already Collected This Error Message But you can show mto us also 🕵🏼 🕵🏼‍♂️: [${error.message}]`);
      console.log(error);
    }

    setIsLoading(false);
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nftnaires | Project List Request</title>
        <meta name="title" content="Project List Request" />
        <meta name="description" content="List Your NFT Project On Nftnaires, First NFT CMS" />
      </Helmet>
      {isLoading && <Loading />}
      <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-xl mx-auto">
          <svg className="absolute left-full transform translate-x-1/2" width={404} height={404} fill="none" viewBox="0 0 404 404" aria-hidden="true">
            <defs>
              <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
          </svg>
          <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
          </svg>
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Submit Your Project</h2>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              After review from our team, Your valuable project will be listed on our platform within 24 hours and visible to thousands of nft collectors.
            </p>
          </div>
          <div className="mt-12">
            <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div className="sm:col-span-2">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Project Name<span className=" text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <input
                    {...register("name", { required: true, maxLength: 30, minLength: 1 })}
                    type="text"
                    max={30}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                  />
                </div>
                <span className=" text-red-500">{errors?.name?.message}</span>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Description<span className=" text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <textarea
                    id="description"
                    name="description"
                    rows={4}
                    {...register("description")}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border border-gray-300 rounded-md"
                    maxLength={250}
                  />
                </div>
                <span className=" text-red-500">{errors?.description?.message}</span>
              </div>

              <div className="sm:col-span-2 ">
                <label htmlFor="logo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Logo<span className=" text-red-500">*</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className=" flex items-center content-between justify-around px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    {logoFile && <img className="w-36 h-36 object-cover rounded-lg" src={URL.createObjectURL(logoFile)} alt="" />}
                    <div className="space-y-1 text-center">
                      <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <label
                          htmlFor="logoFileList"
                          className="relative cursor-pointer bg-white rounded-md font-medium text-primary hover:text-blue-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary"
                        >
                          <span>Upload</span>
                          <input
                            id="logoFileList"
                            name="logoFileList"
                            accept="image/png, image/jpeg, image/gif"
                            type="file"
                            className="sr-only"
                            onChange={(e) => setLogoFile(e.target.files[0])}
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
                    </div>
                  </div>
                  <span className=" text-red-500">{!logoFile && "Must Upload Logo"}</span>
                </div>
              </div>

              <div className="mt-1 sm:col-span-2 border-gray-200 border-b h-1" />
              <div>
                <label htmlFor="total-supply" className="block text-sm font-medium text-gray-700">
                  Total Supply<span className=" text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <input
                    {...register("totalSupply")}
                    type="number"
                    defaultValue={2022}
                    placeholder="8888"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                  />
                </div>
                <span className=" text-red-500">{errors?.totalSupply?.message}</span>
              </div>
              <div>
                <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                  Price<span className=" text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    {...register("price")}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                  />
                  <span className=" text-red-500">{errors?.price?.message}</span>
                </div>
              </div>

              <div>
                <PlatformSelect onSelect={setPlatformId} />
              </div>
              {/* Date */}
              <div>
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                  Launch Date<span className=" text-red-500">*</span>
                </label>
                <div className="mt-5">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      style={{ width: "100%", height: "34px" }}
                      value={launchDate}
                      onChange={handleDateChange}
                      ampm={false}
                      disablePast
                      variant="dialog"
                      // inputVariant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              {/* Date End */}
              <div>
                <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                  Website<span className=" text-red-500">*</span>
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    {...register("website")}
                    className="focus:ring-primary focus:border-primary block w-full pl-4  sm:text-sm border-gray-300 rounded-md"
                    placeholder="https://nftnaires.com"
                  />
                  <span className=" text-red-500">{errors?.website?.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                  Twitter<span className=" text-red-500">*</span>
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    {...register("twitter")}
                    className="focus:ring-primary focus:border-primary block w-full pl-4  sm:text-sm border-gray-300 rounded-md"
                    placeholder="https://twitter.com/nftnaires"
                  />
                  <span className=" text-red-500">{errors?.twitter?.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                  Discord<span className=" text-red-500">*</span>
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    {...register("discord")}
                    className="focus:ring-primary focus:border-primary block w-full pl-4  sm:text-sm border-gray-300 rounded-md"
                    placeholder="https://discord.gg/cPxcMHBW"
                  />
                  <span className=" text-red-500">{errors?.discord?.message}</span>
                </div>
                <p className="mt-2 text-sm text-gray-400" id="email-description">
                  Make sure invitation link won't expire
                </p>
              </div>
              <div className="mt-1 sm:col-span-2 border-gray-200 border-b h-1" />
              <div>
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                  Whitelist End Date<span className=" text-sm text-gray-400"> (Optional)</span>
                </label>
                <div className="mt-2">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      style={{ width: "100%", height: "34px" }}
                      value={whitelistDate}
                      onChange={handleWhitelistDateChange}
                      ampm={false}
                      disablePast
                      variant="dialog"
                      // inputVariant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div>
                <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                  Whitelist Join Link<span className=" text-sm text-gray-400"> (Optional)</span>
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    {...register("whitelistJoinLink")}
                    className="focus:ring-primary focus:border-primary block w-full pl-4  sm:text-sm border-gray-300 rounded-md"
                    placeholder="https://discord.gg/cPxcMHBW"
                  />
                </div>
                <span className=" text-red-500">{errors?.whitelistJoinLink?.message}</span>
              </div>
              <div className="mt-1 sm:col-span-2 border-gray-200 border-b h-1" />
              <div className="sm:col-span-2">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <Switch
                      checked={agreed}
                      onChange={setAgreed}
                      className={classNames(
                        agreed ? "bg-primary" : "bg-gray-200",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      )}
                    >
                      <span className="sr-only">Agree to policies</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          agreed ? "translate-x-5" : "translate-x-0",
                          "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                  </div>
                  <div className="ml-3">
                    <p className="text-base text-gray-500">
                      By selecting this, you agree to the{" "}
                      <a href="#" className="font-medium text-gray-700 underline">
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a href="#" className="font-medium text-gray-700 underline">
                        Cookie Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <button
                  type="submit"
                  disabled={!agreed}
                  className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Submit 🚀 🌔
                </button>
                <p className="mt-8 text-center text-black">{submitResult}</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitProject;
